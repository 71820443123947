export interface HomePageGetStartedModal {
  heading: string
  headingDescription: string
  footerBtn1: string
  footerBtn2: string
  description: string
  section1Title: string
  section1SubTitle: string
  section1Description: string
  section2Title: string
  section2SubTitle: string
  section2Description: string
}

const home: HomePageGetStartedModal = {
  heading: "Getting Started",
  headingDescription: "Design, Print, Stamp & Mail Postcards",
  footerBtn1: "CREATE",
  footerBtn2: "MANAGE",
  description:
    "Capture lost and hard-to-reach patients with monthly recalls sent via our direct mail service.",
  section1Title: "Create POSTAL Campaigns",
  section1SubTitle: "for my practice",
  section1Description:
    "Start using POSTAL to send direct mail campaigns to valued patients.",
  section2Title: "Manage POSTAL Campaigns",
  section2SubTitle: "for my practice",
  section2Description:
    "Manage POSTAL campaigns you've created and used in the past.",
}

export default home
