export interface SelectCopy {
  heading: string
  description: string
  fontSize: string
  color: string
  fontStyle: string
  addVariables: string
  addVariablesBtn: string
  addVariablesInfo: string
}

const selectCopy: SelectCopy = {
  heading: "4. COPY - Add headlines and copy",
  description: "Select a field on the left to edit the copy inside.",
  fontSize: "Font / Size",
  color: "Color",
  fontStyle: "Font Style",
  addVariables: "Add Patient Information",
  addVariablesBtn: "Add Info",
  addVariablesInfo:
    "Add variable patient information to your copy by selecting the text box and location where you want it to appear. Click the Add Info button and select a variable from the list to add it to your copy.",
}

export default selectCopy
