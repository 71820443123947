export interface HomePage {
  heading: string
  descriptionLine1: string
  descriptionLine2: string
  getStarted: string
  reachOut: string
  benefitLine1: string
  benefitLine2: string
}

const home: HomePage = {
  heading: "Welcome to the new POSTAL.",
  descriptionLine1: "Introducing a newly reimagined POSTAL",
  descriptionLine2:
    ", giving you the ability to capture formerly lost customers with monthly recalls sent to hard-to-reach patients via our direct mail service.",
  getStarted: "Get Started",
  reachOut:
    "Reach out to more of your existing patient base by adding POSTAL to monthly recalls. By only sending postcards to patients who don't respond to electronic or voice recalls, you can efficiently and effectively reach the largest percentage of your existing patient base.",
  benefitLine1: "Save time. Save postage.",
  benefitLine2: "Save lost appointments.",
}

export default home
