export interface Footer {
  description: string
  terms: string
  termsPrivacyConnector: string
  privacy: string
}

const footer: Footer = {
  description: "©2020 Postal™ All rights reserved.",
  terms: "Terms of Use",
  termsPrivacyConnector: "and",
  privacy: "Privacy Policy.",
}

export default footer
