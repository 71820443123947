interface SubmitCampaign {
  heading: string
  description: string
  campaign: string
  template: string
  size: string
  inch: string
  schedToPrint: string
  timeToProduce: string
  footerHeading: string
  submittedHeading: string
  submittedDescription: string
  mailingFreqInfo: string
}

const submitCampaign: SubmitCampaign = {
  heading: "Congratulations, you’re almost there!",
  description:
    "Once you confirm and submit all the information you've provided, a 4PatientCare support agent will contact you to finalize your <b>campaign</b> setup and ensure your satisfaction. A true cost estimate will be provided before final <b>approval</b>.",
  campaign: "Campaign",
  template: "Template",
  size: "Size",
  inch: "Inch",
  schedToPrint: "Scheduled to print and mail",
  timeToProduce: "Time to produce",
  footerHeading: "Estimated time for proof generation: 1-2 business days",
  submittedHeading: "Order Submitted",
  submittedDescription:
    "A <b>4PatientCare support</b> agent will contact you shortly.",
  mailingFreqInfo: "Monthly mailings are sent on a biweekly basis.",
}

export default submitCampaign
