import homePage from "./homePage"
import homePageGetStartedModal from "./homePageGetStartedModal"
import homePageGladBackModal from "./homePageGladBackModal"
import footer from "./footer"
import header from "./header"
import createCampaign from "./createCampaign"
import help from "./help"
import selectColor from "./selectColor"
import selectLogo from "./selectLogo"
import selectPhoto from "./selectPhoto"
import selectCopy from "./selectCopy"
import selectDetails from "./selectDetails"
import fileUpload from "./fileUpload"
import fileSelection from "./fileSelection"
import submitCampaign from "./submitCampaign"
import approveCampaign from "./approveCampaign"
import campaigns from "./campaigns"
import mapping from "./mapping"

export default {
  homePage,
  footer,
  header,
  createCampaign,
  help,
  fileUpload,
  fileSelection,
  selectColor,
  selectLogo,
  selectPhoto,
  selectCopy,
  selectDetails,
  homePageGetStartedModal,
  homePageGladBackModal,
  submitCampaign,
  approveCampaign,
  campaigns,
  mapping,
}
