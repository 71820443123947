interface Campaigns {
  campaigns: string
  campaign: string
  action: string
  status: string
  template: string
  campaignId: string
  edited: string
  duplicate: string
  duplicateInfo: string
  edit: string
  review: string
  campaignName: string
  editName: string
  saveName: string
  requiredChangeName: string
  campaignTemplate: string
  campaignTemplateId: string
  downloadPatient: string
  downloadProof: string
  addTemplate: string
  editTemplate: string
  notes: string
  addNotes: string
  saveNotes: string
  close: string
  submit: string
  resubmit: string
  approve: string
  noOtherCampaigns: string
}

const campaigns: Campaigns = {
  campaigns: "Campaigns",
  action: "Action",
  status: "Status",
  campaign: "Campaign",
  campaignId: "Campaign ID",
  template: "Template",
  edited: "Updated",
  duplicate: "Duplicate",
  duplicateInfo:
    "Change the characteristics of a campaign while preserving the original by making a duplicate with a different name.",
  edit: "Edit",
  review: "Review",
  campaignName: "Campaign Name",
  editName: "Edit Name",
  saveName: "Save Name",
  requiredChangeName: "You are required to change the campaign name.",
  campaignTemplate: " Campaign Template",
  campaignTemplateId: " Template ID",
  downloadPatient: "Download Patient / Recipient Data",
  downloadProof: "Download Proof",
  addTemplate: "Add Template",
  editTemplate: "Edit Template",
  notes: "Notes",
  addNotes: "Edit Notes",
  saveNotes: "Save Notes",
  close: "Close",
  submit: "Submit",
  resubmit: "Resubmit",
  approve: "Approve",
  noOtherCampaigns: "You have no other campaigns",
}

export default campaigns
