export interface SelectDetails {
  heading: string
  description: string
  campaignName: string
  campaignNameInfo: string
  templateName: string
  templateNameInfo: string
  paperType: string
  paperTypeInfo: string
  coating: string
  coatingInfo: string
  printedSide: string
  printedSideInfo: string
  prodTime: string
  prodTimeInfo: string
  mailFrequency: string
  mailFrequencyInfo: string
  mailFrequencySubLabel: string
  templateDescription: string
  templateDescriptionInfo: string
  templateDescriptionPlaceholder: string
  addPatient: string
  addPatientVarBtn: string
  btnInfo: string
  campaignDescription: string
  campaignDescriptionInfo: string
  campaignDescriptionPlaceholder: string
}

const selectDetails: SelectDetails = {
  heading: "5. DETAILS - Choose your settings",
  description: "",
  campaignName: "Campaign Name",
  campaignNameInfo: "Name of the campaign",
  templateName: "Template Name",
  templateNameInfo: "Name of the template",
  paperType: "Paper Type",
  paperTypeInfo: "Paper Type",
  coating: "Coating",
  coatingInfo: "Coating",
  printedSide: "Printed Side",
  printedSideInfo: "Printed Side",
  prodTime: "Production Time",
  prodTimeInfo: "Production Time",
  mailFrequency: "Mailing Frequency",
  mailFrequencyInfo:
    "To provide volume discounts, printers bulk-run postcards in groups every two weeks (on the first and middle of each month). Acquisition, Fast and Rush orders are printed on an on-demand basis and do not qualify for these discounts.",
  mailFrequencySubLabel: "Monthly mailings are sent on a bi-weekly basis.",
  templateDescription: "Template Description",
  templateDescriptionInfo:
    "Add notes about this template's mailing parameters and other key information as a quick reference for future use.",
  templateDescriptionPlaceholder:
    "Dallas Area, Turtle Creek Variable, Multiple Sizes",
  addPatient: "Add Patient Information",
  addPatientVarBtn: "Add Info",
  btnInfo:
    "Save your template then click to see your Results. To add more to this campaign, you can <b>duplicate this template</b> and <b>update the Business Rules</b> or <b>create and add a new template</b>.",
  campaignDescription: "Campaign Description",
  campaignDescriptionInfo:
    "Add notes about this campaign's mailing parameters and other key information as a quick reference for future use.",
  campaignDescriptionPlaceholder:
    "Dallas Area, Turtle Creek Variable, Multiple Sizes",
}

export default selectDetails
