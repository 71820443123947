export enum PageOptions {
  PICKTEMPLATE = "pickTemplate",
  ADDTEMPLATE = "addTemplate",
  EDITTEMPLATE = "editTemplate",
  LOGO = "logo",
  COLOR = "color",
  PHOTO = "photo",
  COPY = "copy",
  DETAILS = "details",
  HELP = "help",
  REPORTS = "reports",
  CAMPAIGNS = "campaigns",
  SUBMITCAMPAIGN = "submitCampaign",
  RESUBMITCAMPAIGN = "reSubmitCampaign",
  SUBMITTEDCAMPAIGN = "submittedCampaign",
  APPROVECAMPAIGN = "approveCampaign",
  MAPPING = "mapping",
}

export const navigationRoutes: { [key: string]: string } = {
  [PageOptions.HELP]: "/help",
  [PageOptions.REPORTS]: "/reports",
  [PageOptions.PICKTEMPLATE]: "/create",
  [PageOptions.CAMPAIGNS]: "/campaigns",
  [PageOptions.ADDTEMPLATE]: "/edit/:campaignId/template/create",
  [PageOptions.EDITTEMPLATE]: "/edit/:campaignId/template/:templateId/template",
  [PageOptions.COLOR]: "/edit/:campaignId/template/:templateId/color",
  [PageOptions.LOGO]: "/edit/:campaignId/template/:templateId/logo",
  [PageOptions.PHOTO]: "/edit/:campaignId/template/:templateId/photo",
  [PageOptions.COPY]: "/edit/:campaignId/template/:templateId/copy",
  [PageOptions.DETAILS]: "/edit/:campaignId/template/:templateId/details",
  [PageOptions.SUBMITCAMPAIGN]: "/campaigns/:campaignId/submit",
  [PageOptions.RESUBMITCAMPAIGN]:
    "/campaigns/:campaignId/:campaignRunId/re-submit",
  [PageOptions.SUBMITTEDCAMPAIGN]: "/campaigns/:campaignId/submitted",
  [PageOptions.APPROVECAMPAIGN]:
    "/campaigns/:campaignId/:campaignRunId/approve",
  [PageOptions.MAPPING]: "/mapping",
}

export type CreateCampaign = {
  [navStep in PageOptions]: {
    heading: string
    navTitle?: string
    createContentTitle?: string
    info?: string
    infoBtnText?: string
    infoBtnLink?: string
  }
} & {
  save: string
  next: string
}

const createCampaign: CreateCampaign = {
  save: "Save",
  next: "Next: ADD CONTENT ",
  pickTemplate: {
    heading: "Create&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;Postcard",
    createContentTitle: "Pick a Template",
    info:
      "Start creating your postcard by choosing the template that best suits your business needs: <b>Recall</b> or <b>Acquisition</b>.<br/>You can also build a new template from scratch.",
  },
  addTemplate: {
    heading: "Create&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;Postcard",
    createContentTitle: "Pick a Template",
    info:
      "Start creating your postcard by choosing the template that best suits your business needs: <b>Recall</b> or <b>Acquisition</b>.<br/>You can also build a new template from scratch.",
  },
  editTemplate: {
    heading: "Create&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;Postcard",
    createContentTitle: "Pick a Template",
    info:
      "Start creating your postcard by choosing the template that best suits your business needs: <b>Recall</b> or <b>Acquisition</b>.<br/>You can also build a new template from scratch.",
  },
  color: {
    heading: "Create&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;Postcard",
    navTitle: "Color",
    createContentTitle: "Add Content",
    info:
      "Select a color palette, upload your corporate logo, pick images, add headlines and copy, and adjust your settings for printing.",
  },
  logo: {
    heading: "Create&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;Postcard",
    navTitle: "Logo",
    createContentTitle: "Add Content",
    info:
      "Select a color palette, upload your corporate logo, pick images, add headlines and copy, and adjust your settings for printing.",
  },
  photo: {
    heading: "Create&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;Postcard",
    navTitle: "Photo",
    createContentTitle: "Add Content",
    info:
      "Select a color palette, upload your corporate logo, pick images, add headlines and copy, and adjust your settings for printing.",
  },
  copy: {
    heading: "Create&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;Postcard",
    navTitle: "Copy",
    createContentTitle: "Add Content",
    info:
      "Select a color palette, upload your corporate logo, pick images, add headlines and copy, and adjust your settings for printing.",
  },
  details: {
    heading: "Create&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;Postcard",
    navTitle: "Details",
    createContentTitle: "Add Content",
    info:
      "Select a color palette, upload your corporate logo, pick images, add headlines and copy, and adjust your settings for printing and mailing.",
  },
  help: {
    heading: "Contact Us",
  },
  reports: {
    heading: "Reports",
    createContentTitle: "Manage",
    info:
      "Use campaign reports to break out existing patients from those that are no longer active or available.",
  },
  campaigns: {
    heading: "Campaigns",
    createContentTitle: "Manage",
    info:
      "Manage, duplicate or delete an existing direct mail campaign, or create a new campaign. Access individual templates within a campaign quickly.",
    infoBtnText: "Create New Campaign",
    infoBtnLink: "/create",
  },
  submitCampaign: {
    heading: "Campaigns",
    info:
      "Select a color palette, upload your corporate logo, pick images, add headlines and copy, and adjust your settings for printing and mailing.",
  },
  reSubmitCampaign: {
    heading: "Campaigns",
    info:
      "Select a color palette, upload your corporate logo, pick images, add headlines and copy, and adjust your settings for printing and mailing.",
  },
  submittedCampaign: {
    heading: "Campaigns",
    info:
      "Select a color palette, upload your corporate logo, pick images, add headlines and copy, and adjust your settings for printing and mailing.",
  },
  approveCampaign: {
    heading: "Campaigns",
    info:
      "Select a color palette, upload your corporate logo, pick images, add headlines and copy, and adjust your settings for printing and mailing.",
  },
  mapping: {
    heading: "Mapping",
    createContentTitle: "Manage",
    info:
      "Manage the mapping of content for your campaigns. Any unresolved content issues will surface at the top of the list below.<br/> To resolve content issues, click the red area to resolve the asset in question.",
  },
}

export default createCampaign
