interface FileSelection {
  corporateAssets: string
  sortByLocation: string
  sortByProvider: string
  selectionAppearHere: string
  corporateLibLogoDesc: string
  corporateLibBgDesc: string
  corporateLib: string
  publicAssets: string
  publicLibBgDesc: string
  publicLib: string
}

const fileSelection: FileSelection = {
  corporateAssets: "Corporate Assets",
  corporateLibLogoDesc:
    "Choose one of your corporate branding assets to add or change the logo on the front or back of your postcard.",
  corporateLibBgDesc:
    "Choose one of your corporate assets to add or change an image on the front or back of your postcard.",
  corporateLib: "Corporate Library",
  sortByLocation: "Filter by Location",
  sortByProvider: "Filter by Provider",
  selectionAppearHere: "Selection will appear here",
  publicAssets: "Public Library",
  publicLibBgDesc:
    "Choose one of our free public assets to add or change an image on the front or back of your postcard.",
  publicLib: "Public Library",
}

export default fileSelection
