export interface SelectPhoto {
  heading: string
  description: string
  chooseLibrary: string
  scaleToFit: string
}

const selectPhoto: SelectPhoto = {
  heading: "3. PHOTO - Add your imagery",
  description:
    "Drag a photo over the upload box below, or choose from your library of corporate assets or our public library of images.",
  chooseLibrary: "Choose From Library",
  scaleToFit: "Scale to fit.",
}

export default selectPhoto
