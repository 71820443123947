export interface SelectLogo {
  heading: string
  description: string
  chooseLibrary: string
  sameLogo: string
  scaleToFit: string
}

const selectLogo: SelectLogo = {
  heading: "2. LOGO - Add your branding",
  description:
    "Upload your corporate brand or logo, or choose from your library of corporate assets (if available).",
  chooseLibrary: "Choose From Library",
  sameLogo: "Same logo.",
  scaleToFit: "Scale to fit.",
}

export default selectLogo
