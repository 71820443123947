interface FileUpload {
  browse: string
  maxFileSizeLabel: string
  maxFileSizeValue: string
  acceptableFormats: string
  tagDescription: string
  location: string
  provider: string
}

const fileUpload: FileUpload = {
  browse: "Browse",
  maxFileSizeLabel: "Maximum File Size",
  maxFileSizeValue: "10MB",
  acceptableFormats: "Acceptable Formats",
  tagDescription: "Please tag the location and provider for this logo.",
  location: "Location",
  provider: "Provider",
}

export default fileUpload
