export interface Header {
  create: string
  mapping: string
  campaigns: string
  reports: string
  help: string
  backToDashboard: string
}

const header: Header = {
  create: "Create",
  mapping: "Mapping",
  campaigns: "Campaigns",
  reports: "Reports",
  help: "Help",
  backToDashboard: "Back to Dashboard",
}

export default header
