interface Mapping {
  heading: string
  title: string
  info: string
}

const mapping: Mapping = {
  heading: "Asset Mapping",
  title: "Manage",
  info:
    "Manage the mapping of content for your campaigns. Any unresolved content issues will surface at the top of the list below.<br/> To resolve content issues, click the red area to resolve the asset in question.",
}

export default mapping
