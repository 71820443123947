export interface SelectColor {
  heading: string
  description: string
  palette: string
}

const selectColor: SelectColor = {
  heading: "1. COLOR - Select your color palette",
  description:
    "Choose the palette that most closely matches your corporate colors. All palettes apply to both sides of the postcard (front and back).",
  palette: "Palette",
}

export default selectColor
