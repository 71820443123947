import React from "react"
import { Layout as AntLayout } from "antd"
import "tailwindcss/dist/base.min.css"
import { css } from "twin.macro"
import { ToastProvider } from "react-toast-notifications"

import "./global.css"
import { Localization, possibleOptions } from "@src/localization"
import Header from "./header"
import Footer from "./footer"
import CurrentStep from "@src/context/currentRouteContext"
import useCurrentRoute from "@src/hooks/useCurrentRoute"

const Layout = ({ children }: { children: any }): React.ReactElement => {
  const currentStep = useCurrentRoute()

  return (
    <Localization.Provider value={possibleOptions.en}>
      <CurrentStep.Provider value={currentStep}>
        <ToastProvider>
          <AntLayout hasSider={false}>
            <Header />
            <AntLayout.Content
              css={[
                css`
                  padding-top: 64px;
                `,
              ]}
            >
              {children}
            </AntLayout.Content>
            <Footer />
          </AntLayout>
        </ToastProvider>
      </CurrentStep.Provider>
    </Localization.Provider>
  )
}

export default Layout