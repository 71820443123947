interface ApproveCampaign {
  heading: string
  description: string
  footerHeading: string
  numberOfRecipients: string
  totalRecipients: string
  secondaryDesc: string
  costEstimate: string
}

const approveCampaign: ApproveCampaign = {
  heading: "Confirm and Approve",
  description:
    "Confirming the <b>True Cost Estimate</b> is the final step in approving your campaign. <b>Thank you for your business!</b>",
  footerHeading: "Estimated delivery window: 5-9 business days",
  numberOfRecipients: "Number of recipients",
  totalRecipients: "Total Recipients",
  secondaryDesc:
    "*Cost estimates are based on the number of people in your active mailing list for a campaign and include costs and fees associated with printing and postage. Actual costs may vary.",
  costEstimate: "COST ESTIMATE",
}

export default approveCampaign
