import { HomePageGetStartedModal } from "./homePageGetStartedModal"

const home: HomePageGetStartedModal = {
  heading: "Glad to see you back!",
  headingDescription: "Design, Print, Stamp & Mail Postcards",
  footerBtn1: "MANAGE",
  footerBtn2: "CREATE",
  description:
    "We noticed that you left things unfinished the last time you used POSTAL. Would you like to <b>continue where you left off</b> or <b>start a new campaign</b>?",
  section1Title: "Manage POSTAL Campaigns",
  section1SubTitle: "",
  section1Description:
    "Continue a previous session, creating a direct mail campaign (and templates) to send to your patients.",
  section2Title: "Start New Campaign",
  section2SubTitle: "",
  section2Description:
    "Create a new direct mail campaign (with added templates) to send to your valued patients.",
}

export default home
